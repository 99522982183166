* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "refrigerator-deluxe", sans-serif;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
