.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.black {
  background-color: rgb(224, 224, 224);
  height: 1000px;
}
.blue {
  background-color: rgb(49, 69, 129);
  height: 1000px;
}

.red {
  background-color: red;
  height: 1000px;
}

.home-container {
  max-width: 100% !important;
}
.space {
  height: 5px;
}

.bg-black {
  background-color: black;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
