.social-media-links {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  cursor: pointer;
}

.button {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.15em;
  margin-right: calc(0.15em * -1);
  text-transform: uppercase;
}

.hvr-radial-out:before {
  background-color: #435a88;
}

/* NAVBAR STYLING STARTS */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: rgb(29, 29, 29);
  color: #fff;
}
.nav-links a {
  color: #fff;
  background-color: rgb(29, 29, 29);
}
/* LOGO */
.logo {
  width: 140px;
}
/* NAVBAR MENU */
.menu {
  display: flex;
  gap: 1em;
  font-size: 18px;
}

.menu li {
  padding: 5px 10px;
}

/*RESPONSIVE NAVBAR MENU STARTS*/
/* CHECKBOX HACK */
input[type="checkbox"] {
  display: none;
}
/*HAMBURGER MENU*/
.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
}

.hamburger-margin {
  margin-top: -12px;
}
/* */
@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    background-color: rgb(29, 29, 29);
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
    margin-top: 30px;
    z-index: 1;
  }

  .menu li + li {
    margin-top: 12px;
  }
  .show-menu {
    display: block;
  }

  .hamburger {
    cursor: pointer;
    display: block;
  }
}

.blue {
  background-color: #4267b2;
}

.fa-facebook {
  color: #4267b2;
}

.fa-instagram:hover {
  color: #3f729b;
}

.fa-tiktok {
  color: #ff0050;
}

.fa-tiktok:hover {
  color: #00f2ea;
}
